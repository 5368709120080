
.row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 400px;
}

.row .col-2 {
    flex-basis: 100px;
    flex-grow: 2;
}
.row .col-2 .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    max-width: 90vw;
    overflow: scroll;
}
.row .col-2 .links::-webkit-scrollbar {
    display: none;
}

.row .col-2 .links button {
    background-color: transparent;
    border: none;
    padding: 12px;
    cursor: pointer;
    color: #8892b0 !important;
    font-size: 16px;
    border-left: 2px solid #233554;
    width: 100%;
    transition: all .6s linear;
    text-align: start;
}
.row .col-2 .links button:hover {
    background-color: #112240;
    color: var(--greenC) !important;
}
.row .col-2 .links .active {
    color: var(--greenC) !important;
    border-color: var(--greenC);
}

.row .col-10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 500px;
    flex-grow: 8;
    padding: 5px 15px;
}
.row .col-10 .work {
    opacity: 1;
    transition: all .6s ease;
    animation: fadeIn .6s ease;
}
.row .col-10 .work h3 {
    margin-bottom: 15px;
    color: white;
}
.row .col-10 .work p {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    margin-bottom: 10px;
}
.row .col-10 .work p i {
    color: var(--greenC);
    margin-right: 15px;
    font-size: 13px;
    margin-top: 8px;
}

/* //Light */

.light .row .col-2 .links button {
    color: white !important;
    border-color: #1e8dd6;
}

.light .row .col-2 .links button:hover {
    background-color: #1e8dd6;
    color: #ff9a3e !important;
}

.light .row .col-2 .links .active {
    color: #ff9a3e !important;
    border-color: #ff9a3e !important;
}

.light .row .col-10 .work p i {
   color: #ff9a3e;
}

