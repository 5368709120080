

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
}

:root::-webkit-scrollbar{
    width: 5px;
}
:root::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #0a192f;
}
:root::-webkit-scrollbar-thumb{
    background-color: #495670;
    color: #495670; 
    border-radius: 10px;
}

:root {
    --greenC: #64ffda;
}


a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
}

p {
    line-height: 28px;
}

h1,h2,h3,h4,h5,h6,p,span,li  {
    position: relative;
    z-index: 2;
}


.mono {
    font-family: 'Space Mono', monospace;
}

.text-green {
    color: var(--greenC);
}

.outlineBtn {
    border: 1px solid var(--greenC) !important;
    color: var(--greenC);
    background-color: transparent;
    padding: 8px;
    font-size: 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .4s ease;
}

.outlineBtn:hover {
    background-color: rgb(58, 250, 202, .08);
}

.title {
    font-size: 30px;
    width: fit-content;
    margin-bottom: 20px;
    display: flex;
    color: white;
}

.subtitle {
    text-align: center;
    color: white;
    margin-bottom: 50px;
}

.pretitle {
    font-size: 17px;
    color: var(--greenC);
    text-align: center;
}

.title span {
    color: var(--greenC);
    font-size: 22px;
    font-family: 'Space Mono', monospace;
}

.title::after {
    position: relative;
    content: "";
    top: 20px;
    background-color: #1a2b5c;
    width: 300px !important;
    height: 1px;
    display: block;
    margin-left: 10px;
}

.main {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #0a192f;
    color: #8892b0;
    font-size: 16.5px;
    font-family: 'Inter', sans-serif;
}

section {
    width: 100%;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 60px;
    position: relative;
}

.content {
    margin: 0 auto;
    width: min(100%, 1000px);
    padding: 20px;
}

/* // header */

.header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-height: 100vh;padding-top: 35px;
}

.header span {
    color: var(--greenC);
}
.header h4 {
    font-size: 60px;
}

.header h4:first-of-type {
    color: #b2bbd6;
}
.header p {
    max-width: 500px;
    line-height: 28px;
}
.header .checkBtn {
    max-width: 200px;
    margin-top: 30px;
    font-size: 20px;
}

/* About */

.about .row {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    flex-wrap: wrap;
}

.about .img-cont {
    position: relative;
}

.about .img-cont img {
    max-width: 300px;
    border-radius: 4px;
    z-index: 3;
    position: relative;
    transition: all .4s ease;
}

.about .img-cont::before {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: #62ffda;
    opacity: .5;
    border-radius: 4px;
    z-index: 9;
}

.about .img-cont::after {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--greenC);
    border-radius: 4px;
    z-index: 1;
    transform: translateX(15px) translateY(15px);
    transition: all .4s ease;
}

.about .img-cont:hover::before {
    background-color: transparent !important;
}

.about .img-cont:hover::after {
    transform: translateX(10px) translateY(10px);

}

.about .col-8 {
    flex-basis: 300px;
    flex-grow: 6;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.about .col-8 .skills-box {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, minmax(140px, 200px));
    gap: 5px;
    font-size: 14px;
}
.skills-box li span {
    color: var(--greenC);
}

.about .col-4 {
    flex-basis: 200px;
    flex-grow: 4;
    display: flex;
}

/* Work */

.experience .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    margin: 0 auto;
}

/* Main Projects */

.mainProjects {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* Contact */

.contact {
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 15px;
}

.contact h2 {
    color: white;
    font-size: 50px;
    text-align: center;
}

.contact p {
    max-width: 60%;
    text-align: center;
    margin-top: 10px;
}

.contact a {
    padding: 15px 25px;
    margin: 30px 0;
}

/* footer */

footer {
    width: 100%;
    padding: 20px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
}

footer .media-box {
    display: none;
}

footer .media-box ul {
    display: flex;
    gap: 10px;
    font-size: 28px;

}

footer .media-box ul li i {
    transition: all .4s ease;
}

footer .media-box ul li:hover i{
    color: var(--greenC);
}

/* Not Found */

.notFound {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.notFound h2 {
    font-size: 180px;
    font-family: 'Space Mono', monospace;
    line-height: 1;
    color: var(--greenC);
}

.notFound h3 {
    font-size: 38px;
    font-weight: normal;
    color: white;
}

.notFound .button {
    padding: 10px 20px;
    margin: 40px;
}

/* // Light Theme */

.light {
    background-color: #55a5db !important;
    color: white !important;
}

.light .pretitle {
    color: #ff9a3e !important;
}


.light .title span {
    color: #ff9a3e;
}
.light .outlineBtn {
    color: #e9791a;
    border-color: #ff9a3e !important;
}

.light .outlineBtn:hover {
    background-color: rgb(233, 121, 26, .4) !important;
}


.light span,.light p,.light a,.light  button {
    color: white;
}

.light .header h4:first-of-type {
    color: #ffb167;
}

.light .about .img-cont::after {
    border-color: #ff9a3e !important;
}

.light .about .img-cont::before {
    background-color: #ff9a3e;
}

.light .about .skills-box li span {
    color: #ff9a3e !important;
}

.light .title::after {
    background-color: #ff9a3e !important;
}

.light footer .media-box ul li:hover i{
    color: #ff9a3e;
}

/* Animations */

.fadeInTop {
    animation: fadeIn-fromTop .4s ease forwards;
}

.fadeInBot {
    animation: fadeIn-fromBottom .6s ease forwards;
}

.fadeInRight {
    animation: fadeIn-fromRight .6s ease forwards;
}

.animaTop {
    transition: all .4s ease;
    opacity: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn-fromTop {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes fadeIn-fromBottom {
    from {
        opacity: 0;
        transform: translateY(40px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn-fromRight {
    from {
        opacity: 0;
        transform: translateX(200px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Media */

@media (max-width:1200px) {
    .main .content {
        padding: 20px 80px;
    }
}

@media (max-width:1080px) {
    .main .content {
        padding: 20px 120px;
    }

    .about .row .col-4 {
        align-items: center;
        justify-content: center;
    }

    .side-left {
        left: 25px !important;
    }

    .side-right {
        right: 25px !important;
    }
}

@media  (max-width:700px) {

    .title {
        margin-bottom: 20px;
        font-size: 24px ;
        align-items: center;
    }

    .title::after {
        display: none;
    }

    .main .content {
       padding: 20px;
    }

    .sideBars {
        display: none;
    }

    /* Navbar */

    .navbar .menu-btn {
        display: flex;
    }

    .navbar .links {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 50%;
        height: 100vh;
        background-color: #112240;
        flex-direction: column;
        margin: 0;
        align-items: flex-start;
        display: none;
        padding: 80px 20px 40px 20px;
        gap: 30px;
        z-index: 98;
        animation: fadeIn-fromRight .4s ease forwards;
    }

    .navbar .links button,
    .navbar .links a  {
        font-size: 20px !important;
    }

    .navbar .showMenu {
        display: flex !important;
    }

    .links #themeBtn img,
    .links #languageBtn img  {
        max-width: 45px;
    }
    
    .light .navbar .links {
        background-color: #5DADE2;
    }


    .light .navbar .links #themeBtn img {
        background-color: rgb(7, 106, 255) !important;
    }


    /* About */

    .about .row {

    }

    .about .row .skills-box {
        justify-content: center;
        margin: 20px 0;
    }

    .about .col-8 .skills-box {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, minmax(140px, 200px));
        gap: 5px;
        font-size: 14px;
    }


    /* Experience */

    .experience .container {
        width: 100%;
    }

    .experience .container .col-2 .links {
        flex-direction: row;
        align-items: flex-end;
    }

    .experience .container .col-2 .links button {
        border-left: none;
        border-bottom: 2px solid #233554;
    }

    .experience .container .col-2 .links .active {
        border-left: none;
        border-bottom: 2px solid var(--greenC);
    }

    .experience .container .work {
        margin-top: 20px;
    }

    /* Main Projects */

    .mainProject .main-card {
        margin: 40px 0;
        position: relative;
        width: 100%;
    }

    .mainProject .main-card .main-card-cont {

    }

    .mainProject .main-card .image {
        transform: translateY(40px);
    }

    .mainProject .main-card .image::before {
        display: none;
    }

    .main-card .main-card-cont .info .infoHeader {
        align-self: center;
    }

    .main-card .main-card-cont .info .buttons a {
        font-size: 35px;
    }

    /* Contact */

    .contact h2 {
         font-size: 35px;
    }

    /* Footer */

    footer .media-box {
        display: flex;
    }

}

@media (max-width:500px) {

    .navbar .links {
        width: 60%;
    }

    .header h4 {
        font-size: 40px;
    }

    .mainProject .main-card .image {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
    }

    .mainProject .main-card .info {
        width: 100%;
        padding: 20px;
        transform: translateY(60px);
    }

    .mainProject .main-card .info .description {
        transform: translateX(0) !important;
    }

    .mainProject .main-card .image::before {
        display: flex;
        background-color: #112240 !important;
        opacity: .3;
    }

    .main-card .main-card-cont .info .infoHeader {
        align-self: unset;
    }
}

@media (max-width:380px) {

    .header h4 {
        font-size: 30px;
    }
}